import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/page-header.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/side-menu/side-menu.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/side-menu/ui/side-menu-group/side-menu-group.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/side-menu/ui/side-menu-item/side-menu-item.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/favorites-button/favorites-button.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-footer/page-footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["CounterDynamic"] */ "/app/src/features/page-header/favorites-button/counter-dynamic.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/menu-button/menu-button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/notification-line/notification-line.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/poizon-shop.svg");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/profile-button/profile-button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/search-button/search-button.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/side-menu/ui/side-menu-item-with-children/side-menu-item-with-children.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/features/page-header/unicorn.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/adjustments-horizontal.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/arrow-2-left.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/arrow-2-right.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/arrow-back.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/box.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/brand-telegram.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/bxs-map.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/check.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/checkbox.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/chevron-down.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/chevron-left.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/chevron-right.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/clock-hour-3.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/copy.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/currency-yuan.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/discount-2.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/external-link.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/fire.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/gps.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/guarantee.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/high-voltage.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/hugging-face.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/info-circle.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/instagram-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/logout.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/long-arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/map-pin.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/menu.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/message-chatbot.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/metro-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/money-mouth-face.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/money-with-wings.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/package.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/phone.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/play.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/refresh.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/rocket.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/ruler-2.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/search.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/send.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/settings.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/shield-chevron.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/shopping-cart.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/smiling-face-with-hearts.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/snail.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/status-icon/check.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/status-icon/flag.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/status-icon/package.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/status-icon/truck-delivery.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/telegram-dark-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/telegram-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/thumbs-up.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/truck-2.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/truck-delivery.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/truck.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/user.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/vk-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/x.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/yandex-navigator.svg");
;
import(/* webpackMode: "eager" */ "/app/src/shared/ui/icons/youtube-play.svg");
;
import(/* webpackMode: "eager", webpackExports: ["TelegramLink"] */ "/app/src/shared/ui/telegram-link/telegram-link.tsx");
